.navbar{
    background-color: #2E4900;
    color: #84814F;
}

.navbar-button{
    background-color: #84814F;
    outline-color: #B2B2A9;
}
.navbar-button:hover{
    background-color: #B2B2A9;
    outline-color: #84814F;
}